import React, { useEffect } from "react";
import { Container, Navbar, Nav, Row, Col } from "react-bootstrap";
import Home from "../Components/Home/Home";
import { Route, Routes, useLocation } from "react-router-dom";
import MovieDetails from "../Components/MovieDetails/MovieDetails";
import ListingScreen from "../Components/Listing/Listing";
import NotFound from "./NotFound";
import ReactGA from 'react-ga4';
import initializeAnalytics from '../analytics';

function Content() {
  const location = useLocation();
  // Component to track page views on route change
  // function usePageTracking() {
   

  //   useEffect(() => {
  //     ReactGA.send({
  //       hitType: "pageview",
  //       page: location.pathname + location.search,
  //     });
  //   }, [location]);
  // }

  // useEffect(() => {
  //   initializeAnalytics();
  // }, []);

  // usePageTracking(); // Hook to track pages

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/movie/:moviename/:id" element={<MovieDetails />} />
      <Route path="movie-list/:listType" element={<ListingScreen />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Content;
