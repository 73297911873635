import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import GoToTop from "../GlobalComponents/GotoTop/GotoTop";


function Footer() {
  return (
    <>
      {/* Footer */}
      <footer className="bg-dark text-white py-4 mt-auto">
        <GoToTop />

        <Container>
          <Row>
            <Col className="text-center">
              <p>&copy; 2024 My Website. All rights reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
