import { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes, Lin, useLocation } from "react-router-dom";
import Content from "./Layout/Content";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import ReactGA from 'react-ga4';
const TRACKING_ID = process.env.REACT_APP_MEASUREMENT_ID; // your Measurement ID

function App() {
  return (
    <div className="App">
      <Router basename="/">
        <Header />
        <Content />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
