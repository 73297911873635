import React, { useEffect, useState, Suspense, lazy } from "react";
import axios from "axios";
import { Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";
import MetaData from "../../GlobalComponents/MetaData";

const Home = () => {
  // Lazy load the component
  const LazyComponent = lazy(() => import("./SliderLayout"));

  const posterUrl = `https://image.tmdb.org/t/p/w500`;

  const API_URL = process.env.REACT_APP_PORT;

  const pageNo = 1; // Common page number
  const language = "en-US"; // Common language

  const typeOfMovieList = {
    nowPlaying: `movie/now_playing?language=${language}&page=${pageNo}`,
    trendingList: `trending/all/day?language=${language}`,
    upComing: `movie/upcoming?language=${language}&page=${pageNo}`,
    popularList: `movie/popular?language=${language}&page=${pageNo}`,
  };

  const listTitles = {
    nowPlaying: "Now Playing",
    popularList: "Popular Movies",
    trendingList: "Trending Movies",
    upComing: "Upcoming Movies",
  };

  const [moviesNowPlaying, setMoviesNowPlaying] = useState([]); // Popular Movies
  const [moviesPopular, setMoviesPopular] = useState([]); // Popular Movies
  const [moviesTrending, setMoviesTrending] = useState([]); // Trending Movies
  const [moviesUpcoming, setMoviesUpcoming] = useState([]); // Upcoming Movies

  const homeSliderList = [
    {
      sliderName: listTitles.nowPlaying,
      sliderData: moviesNowPlaying,
    },
    {
      sliderName: listTitles.popularList,
      sliderData: moviesPopular,
    },
    {
      sliderName: listTitles.trendingList,
      sliderData: moviesTrending,
    },
    {
      sliderName: listTitles.upComing,
      sliderData: moviesUpcoming,
    },
  ];

  // Create Movie URL
  const createMovieUrl = (title, id) => {
    // Generate URL-friendly movie name (replace spaces with hyphens, remove special characters)
    if (title) {
      const movieName = title
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/(^-|-$)+/g, "");

      return `/movie/${movieName}/${id}`;
    } else {
    }
  };

  const fetchMovies = async (listType, setListType, pageNo = 1) => {
    try {
      const response = await axios.get(`${API_URL}/api/movies`, {
        params: {
          listType: listType,
          page: pageNo,
          language: language
        },
      });

      setListType(response.data.results);
    } catch (error) {
      console.error("Error fetching data from backend:", error);
    }
  };

  useEffect(() => {
    fetchMovies(typeOfMovieList.nowPlaying, setMoviesNowPlaying);
    fetchMovies(typeOfMovieList.popularList, setMoviesPopular);
    fetchMovies(typeOfMovieList.trendingList, setMoviesTrending);
    fetchMovies(typeOfMovieList.upComing, setMoviesUpcoming);
  }, []);

  return (
    <Container>
      <Helmet>
        <title>{MetaData.siteName}</title>
        <meta name="description" content={MetaData.metaDescription} />
        <meta name="keywords" content={`${MetaData.metaKeyWords}`} />
        <meta property="og:title" content={MetaData.siteName} />
        <meta property="og:description" content={MetaData.metaDescription} />
        <meta property="og:image" content={`${MetaData.metaDescription}`} />
      </Helmet>
      {/* Sliders */}
      {homeSliderList.map((homeSlider) => (
        <Suspense
          fallback={
            <p className="text-center">Loading {homeSlider.sliderName}...</p>
          }
        >
          <LazyComponent
            sliderTitle={homeSlider.sliderName}
            movieList={homeSlider.sliderData}
            createMovieUrl={createMovieUrl}
            posterUrl={posterUrl}
            listScreenUrl={homeSlider.sliderName}
          />
        </Suspense>
      ))}
    </Container>
  );
};

export default Home;
