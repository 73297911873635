import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HeartRate } from "../../GlobalComponents/ImgPaths";
import { Helmet } from "react-helmet";
import MetaData from "../../GlobalComponents/MetaData";

const ListingScreen = () => {
  const { listType } = useParams();
  const posterUrl = `https://image.tmdb.org/t/p/w500`;

  const API_URL = process.env.REACT_APP_PORT;

  const [moviesList, setMoviesList] = useState([]); // Popular Movies
  const [pageNo, setPageNo] = useState(1); // Initial page number
  const [currentListType, setCurrentListType] = useState(null);

  const typeOfMovieList = {
    nowPlaying: "movie/now_playing?language=en-US",
    trendingList: "trending/all/day?language=en-US",
    upComing: "movie/upcoming?language=en-US",
    popularList: "movie/popular?language=en-US",
  };

  const listData = (() => {
    switch (listType) {
      case "now-playing":
        return typeOfMovieList.nowPlaying;

      case "popular-movies":
        return typeOfMovieList.popularList;

      case "upcoming-movies":
        return typeOfMovieList.trendingList;

      default:
        return typeOfMovieList.trendingList;
    }
  })();

  // Create Movie URL
  const createMovieUrl = (title, id) => {
    // Generate URL-friendly movie name (replace spaces with hyphens, remove special characters)
    if (title) {
      const movieName = title
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/(^-|-$)+/g, "");

      return `/movie/${movieName}/${id}`;
    } else {
    }
  };

  const fetchMovies = async (
    movieListType,
    setMovieListType,
    page = pageNo,
    append = false
  ) => {
    try {
      const response = await axios.get(`${API_URL}/api/movies`, {
        params: {
          listType: movieListType,
          page: pageNo,
        },
      });

      if (append) {
        setMovieListType((prevMovies) => [
          ...prevMovies,
          ...response.data.results,
        ]);
      } else {
        setMovieListType(response.data.results);
      }
    } catch (error) {
      console.error("Error fetching data from backend:", error);
    }
  };

  useEffect(() => {
    console.log("ListType", listType == currentListType);
    // Fetch the movies with the updated parameters
    fetchMovies(
      listData,
      setMoviesList,
      listType === currentListType ? pageNo : 1,
      listType === currentListType
    );
    setCurrentListType(listType);
  }, [listData, pageNo, listType]);

  const loadMoreMovies = () => {
    setPageNo((prevPage) => {
      const newPage = prevPage + 1;
      // fetchMovies(listData, setMoviesList, newPage, true);
      return newPage;
    });
  };

  return (
    <>
      <Helmet>
        <title>
          {listType
            ?.replace("-", " ")
            .replace(/\b\w/g, (char) => char.toUpperCase())}{" "}
          | {MetaData.siteName}
        </title>
        <meta name="description" content={listType} />
        <meta
          name="keywords"
          content={MetaData.metaKeyWords}
        />
        <meta property="og:title" content={listType} />
        <meta property="og:description" content={listType} />
        {/* <meta
          property="og:image"
          content={`${posterUrl}/${movie.poster_path}`}
        /> */}
      </Helmet>

      <Container>
        <Row className="py-5">
          {moviesList.map((movie) => (
            <Col md={4} lg={2} className="mb-3 list-card-home" key={movie.id}>
              <Link to={createMovieUrl(movie.title, movie.id)}>
                <Card key={movie.id}>
                  <Card.Img
                    variant="top"
                    src={`${posterUrl}/${movie.poster_path}`}
                  />
                  <Card.Body>
                    <span className="rating-block">
                      <img
                        src={HeartRate}
                        className="rating-img"
                        alt={`${movie.title} Rating`}
                      />{" "}
                      {Math.round(movie.vote_average * 10)}%
                    </span>
                    <Card.Title>{movie.title}</Card.Title>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
          {moviesList && (
            <Col md={12} className="text-center">
              <Button className="btn-load-more" onClick={loadMoreMovies}>
                Show More Movies
              </Button>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default ListingScreen;
