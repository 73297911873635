import React, { useEffect, useState } from "react";
import axios from "axios";
import { Row, Col, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import sliderSettings from "./SliderSettings";

const MovieDetails = () => {
  const posterUrl = `https://image.tmdb.org/t/p/w500`;

  const { id } = useParams(); // Get the movie ID from the URL
  const [movie, setMovie] = useState(null);
  const [movieCredits, setMovieCredits] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Convert Release Date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  // Convert Time
  const formatTime = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  };

  const fetchMovieDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PORT}/api/movie/${id}`
      );
      setMovie(response.data);
      setError(false);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  // Movie Credits
  const fetchMovieDetailsCredits = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PORT}/api/movie/${id}/credits`
      );
      setMovieCredits(response.data);
      setError(false);
      console.log(response.data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMovieDetails();
    fetchMovieDetailsCredits();
  }, []);

  return (
    <>
      {!loading && (
        <>
          <Helmet>
            <title>{movie?.title} | MoviezInc.</title>
            <meta name="description" content={movie?.overview} />
            <meta
              name="keywords"
              content={`${movie?.genres?.map((gen) => <>{gen?.name}, </>)} ${
                movie.title
              }`}
            />
            <meta property="og:title" content={movie.title} />
            <meta property="og:description" content={movie?.overview} />
            <meta
              property="og:image"
              content={`${posterUrl}/${movie?.poster_path}`}
            />
          </Helmet>
          <div className="movie-details-header">
            <img
              src={`${posterUrl}/${movie.backdrop_path}`}
              className="movie-details-header-img"
              alt={movie.title}
            />
          </div>
          <Container className="my-3 movie-details">
            {!error && (
              <Row>
                <Col lg={9} md={8}>
                  <Row>
                    <Col md={12}>
                      <h1 className="movie-details-title">{movie.title}</h1>
                      <p className="movie-details-meta">
                        {formatTime(movie.runtime)} | Theaters{" "}
                        {formatDate(movie.release_date)}
                      </p>
                      <div className="movie-overview">{movie.overview}</div>
                    </Col>
                  </Row>

                  <div className="crew-slider">
                    <h3>Cast and Crew</h3>
                    <Col className="px-4">
                      <Slider {...sliderSettings}>
                        {movieCredits?.cast?.slice(0, 10).map((credit) => (
                          <div className="crew-block">
                            <img
                              src={`${posterUrl}/${credit.profile_path}`}
                              alt={credit.name}
                            />
                            <span className="artist-name">{credit.name}</span>{" "}
                            as
                            <span className="charecter-name">
                              {credit.character}
                            </span>
                          </div>
                        ))}
                      </Slider>
                    </Col>
                  </div>
                </Col>

                <Col lg={3} md={4}>
                  <img
                    src={`${posterUrl}/${movie.poster_path}`}
                    alt={movie.title}
                  />
                </Col>
              </Row>
            )}

            {error && <p>Failed to load. Please try after sometime.</p>}
          </Container>
        </>
      )}

      {loading && (
        <Container className="my-3">
          <Row>
            <Col md={12}>
              <h3 className="text-center">Loading...</h3>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default MovieDetails;
