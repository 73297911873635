import React from "react";
import { Container, Navbar, Nav, Row, Col } from "react-bootstrap";
import Logo from "../Assets/Images/logo.png";
import { NavLink } from "react-router-dom";

function Header() {
  return (
    <>
      {/* Header */}
      <header>
        <Navbar expand="lg" className="den-header">
          <Container>
            <Row className="w-100">
              {/* Logo */}
              <Col xs={12} md={4} className="d-flex align-items-center">
                <Navbar.Brand>
                  <NavLink to="/">
                    <img
                      src={Logo} // Replace with your logo source
                      height="40"
                      alt="My Website Logo"
                    />
                  </NavLink>
                </Navbar.Brand>
              </Col>

              {/* Navbar */}
              <Col xs={12} md={8}>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse
                  id="basic-navbar-nav"
                  className="justify-content-end"
                >
                  <Nav>
                    <NavLink to="movie-list/now-playing" activeClassName="active-link">Now Playing</NavLink>
                    <NavLink to="movie-list/popular-movies" activeClassName="active-link">Popular</NavLink>
                    <NavLink to="movie-list/trending-movies" activeClassName="active-link">Trending</NavLink>
                  </Nav>
                </Navbar.Collapse>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </header>
    </>
  );
}

export default Header;
